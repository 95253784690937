
const $likeButtons = $('.comment-reactions .like');

$likeButtons.click(function(e) {
    e.preventDefault();

    const $likeButton = $(this);
    const $reactions = $likeButton.parents('.comment-reactions');
    const $likeCount = $reactions.find('.like-count > span')

    let data = new FormData();
    data.append('action', 'like_comment');
    data.append('comment_id', $likeButton.data('commentId'));
    data.append('status', $likeButton.hasClass('liked') ? -1 : 1);

    if ($likeButton.hasClass('liked')) {
        $likeButton.removeClass('liked');
        $likeButton.find('span').text('Lik');
    } else {
        $likeButton.addClass('liked');
        $likeButton.find('span').text('Liker');
    }

    $.ajax({
        url: feltpost_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function (error) {

        },
        complete: function (data) {
            const results = data.responseJSON;
            const likes = results.likes;

            const $userList = $reactions.find('.user-likes ul');
            $userList.empty();

            for (const like of likes) {
                $userList.append(`<li>${like}</li>`);
            }

            $likeCount.html(likes.length + ' liker');

            if (results.likes.length > 0) {
                $reactions.addClass('has-likes');
            } else {
                $reactions.removeClass('has-likes');
            }
        }
    });

})

const $removeButtons = $('.comment-reactions .remove-comment');
$removeButtons.click(function(e) {
    e.preventDefault();

    const confirmed = confirm('Er du sikker på at du vil slette kommentaren?');
    if (!confirmed) {
        return;
    }

    const $parent = $(this).parents('article.comment');
    const commentId = $parent.attr('id').split('-')[1];

    let data = new FormData();
    data.append('action', 'remove_comment');
    data.append('comment_id', commentId);

    $.ajax({
        url: feltpost_scripts.ajax_url,
        type: 'POST',
        data: data,
        cache: false,
        processData: false,
        contentType: false,
        error: function (error) {

        },
        complete: function (data) {
            const response = data.responseJSON;

            if (response.status === 'ok') {
                $parent.remove();
            }
        }
    });

});